import { CONTENTS_DETAIL_PATH, INTERVIEW_DETAIL_PATH } from '@/constants/routes'
import useCustomRouter from '@/features/common/hooks/useCustomRouter'
import { useHeaderState } from '@/features/common/stores/headerState'
import { useEffect } from 'react'

const useHeaderHandler = () => {
  const { pathname } = useCustomRouter()
  const { isChangeColor, isChangeColorPath, isMouseOver, setIsChangeColor, setIsChangeColorPath, setIsMouseOver } =
    useHeaderState()

  const handleChangeHeader = () => {
    if (window.scrollY >= 620) {
      setIsChangeColor(false)
    } else {
      setIsChangeColor(true)
    }
  }

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }

  useEffect(() => {
    if (!isChangeColorPath) return setIsChangeColor(false)

    window.addEventListener('scroll', handleChangeHeader)
  })

  useEffect(() => {
    if (!isChangeColorPath) return setIsChangeColor(false)
  }, [isChangeColorPath])

  useEffect(() => {
    setIsChangeColorPath(pathname.includes(INTERVIEW_DETAIL_PATH) || pathname.includes(CONTENTS_DETAIL_PATH))
  }, [pathname])

  return { isChangeColor, isMouseOver, handleMouseOver, handleMouseOut }
}

export default useHeaderHandler
