import Image from 'next/image'
import { useMediaQuery } from 'react-responsive'
import style from './ContentsBox.module.scss'
import { CONTENTS_PATH } from '@/constants/routes'
import { useCustomRouter, useFadeInAnimation } from '@/features/common/hooks'
import Tag from '@/features/common/components/CardTag'
import { sendCustomEvent } from '@/utils/GTM'
import ArticleModel from '@/features/common/models/articleModel'

interface IContentsBox {
  articleModel: ArticleModel
}

function ContentsBox({ articleModel }: IContentsBox) {
  const isMobile = useMediaQuery({ minWidth: 1193 })
  const { handlePush } = useCustomRouter()
  const { animationFadeInUp: animationFadeInUpWithoutThrottle } = useFadeInAnimation(0)

  const handleChangeRoute = (url: string) => {
    sendCustomEvent({
      action: 'click',
      category: 'route - contents',
      label: articleModel.title,
      value: articleModel.title,
    })
    handlePush(url)
  }

  return (
    <article
      {...(isMobile ? animationFadeInUpWithoutThrottle[articleModel.id] : {})}
      className={style.container}
      onClick={() => handleChangeRoute(`${CONTENTS_PATH}/${articleModel.id}`)}
    >
      <div className={style.container__image}>
        <Tag name={articleModel.tag} />
        <Image src={articleModel.thumbnail} alt={`더 운반 컨텐츠 이미지 ${articleModel.id}`} width={200} height={200} />
      </div>
      <div className={style.container__contents}>
        <h3 dangerouslySetInnerHTML={{ __html: articleModel.title }} />
        <p className={style.container__contents__description}>
          <span>{articleModel.description}</span>
        </p>
      </div>
    </article>
  )
}

export default ContentsBox
