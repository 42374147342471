import { useState } from 'react'

export const useHeaderState = () => {
  const [isChangeColor, setIsChangeColor] = useState<boolean>(true)
  const [isChangeColorPath, setIsChangeColorPath] = useState<boolean>(true)
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false)

  return {
    isChangeColor,
    isChangeColorPath,
    isMouseOver,
    setIsChangeColor,
    setIsChangeColorPath,
    setIsMouseOver,
  }
}
