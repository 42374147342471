import classNames from 'classnames'
import React, { ButtonHTMLAttributes } from 'react'
import style from './DownloadButton.module.scss'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  width?: string
  styleType?: 'default' | 'line'
}

function DownloadButton({ children, width = 'auto', styleType = 'default', ...props }: IButton) {
  return (
    <button
      {...props}
      className={classNames(style.button_container, style[styleType])}
      style={{
        width: width,
      }}
    >
      {children}
    </button>
  )
}

export default DownloadButton
